<template>
  <service-template
    :icon="require('@/assets/images/icons/services/header/OCR.svg')"
    :run="handleRun"
    :run-disabled="(!uploadedImage && index === -1) || isLoading"
    type="image"
    service="aiu"
  >
    <template v-slot:header>
      {{ $t('services.opticalCharacterRecognition.header') }}
    </template>
    <template v-slot:left>
      <input-selection
        title="Choose Image Category"
        :value="category"
        :options="categories"
        :loading="isLoading"
        @input="(value) => category = value"
      />
      <input-language
        v-if="isGeneral"
        class="input-language"
        :step="2"
        :languages="availableLanguages"
        :current-language.sync="lang"
        :loading="isLoading"
      />
      <carousel
        class="example-images-carousel"
        :step="2 + additionalStep"
        :title="$t(`services.carousel.title`)"
      >
        <el-image
          v-for="(example, i) in images"
          :key="i"
          :slot="`slide-${i}`"
          :disabled="isLoading"
          :class="{ 'swiper-selected': index === i }"
          :src="example.path"
          fit="cover"
          class="example"
          @click="() => handleExampleClick(i)"
        />
      </carousel>
      <upload
        :auth="isAuthenticated()"
        :file-size="2"
        :example-file="exampleFile.path"
        :disabled="isLoading"
        :handle-remove="handleUploadRemove"
        :handle-upload="handleUploadSuccess"
      />
    </template>
    <template v-slot:right>
      <generated-result
        :step="3 + additionalStep"
        :service-name="$t('services.opticalCharacterRecognition.header')"
        :loading="isLoading"
      >
        <div
          v-if="Object.keys(content).length > 0"
          class="result-container flex flex-1 flex-col"
        >
          <el-image
            v-if="content.image"
            :src="imageSrc"
            :preview-src-list="[imageSrc]"
            :z-index="9001"
            class="result-container__image"
          />
          <template v-if="isGeneral">
            <p
              v-for="(result, i) in content.result"
              :key="i"
              :class="{ rtl: lang === $consts.lang.AR }"
              class="result-container__text"
            >
              <span>{{ `[${i + 1}] ` }}</span>{{ result.text }}
            </p>
          </template>
          <table v-else>
            <thead>
              <th>Type</th>
              <th>Value</th>
              <th>Confidence</th>
            </thead>
            <tbody>
              <template v-for="([key, value]) in content.result">
                <template v-if="$api.aiu.ocr.labelMap[key]">
                  <tr :key="key">
                    <td>{{ $api.aiu.ocr.labelMap[key] }}:</td>
                    <td>{{ value }}</td>
                    <td>{{ (content.confidence[key] * 100).toFixed(2) }}%</td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
          <div class="download-image">
            <download-image
              :src="imageSrc"
              hidden-if-empty
            />
          </div>
        </div>
      </generated-result>
    </template>
  </service-template>
</template>

<script>
import { mapGetters } from 'vuex';
import ErrorService from '@/service/utils/error';
import Upload from '@/components/Upload/Index.vue';
import Carousel from '@/components/Carousel/Index.vue';
import DownloadImage from '@/components/DownloadImage/Index.vue';
import InputLanguage from '@/views/pages/AIServices/components/InputLanguage/Index.vue';
import InputSelection from '@/views/pages/AIServices/components/InputSelection/Index.vue';
import ServiceTemplate from '@/views/pages/AIServices/components/ServiceTemplate/Index.vue';
import GeneratedResult from '@/views/pages/AIServices/components/GeneratedResult/Index.vue';
import GeneralIcon from '@/assets/images/icons/services/AIU/OCR/general.svg';
import GeneralIconActive from '@/assets/images/icons/services/AIU/OCR/general-active.svg';

export default {
  components: {
    DownloadImage,
    InputLanguage,
    InputSelection,
    ServiceTemplate,
    GeneratedResult,
    Carousel,
    Upload,
  },
  data() {
    return {
      category: this.$api.aiu.ocr.imageCategories.general.id,
      index: -1,
      content: {},
      examples: {},
      isLoading: false,
      uploadedImage: null,
      lang: this.$consts.lang.EN,
      availableLanguages: [this.$consts.lang.EN, this.$consts.lang.AR],
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    categories() {
      const { imageCategories } = this.$api.aiu.ocr;
      const categories = Object.values(imageCategories);
      const {
        general,
      } = imageCategories;
      const icons = {
        [general.id]: this.category === general.id ? GeneralIconActive : GeneralIcon,
      };

      return categories.map((category) => ({ ...category, icon: icons[category.id] }));
    },
    imageSrc() {
      if (!this.content.image) return '';

      return `data:image/png;base64,${this.content.image}`;
    },
    isGeneral() {
      return this.category === this.$api.aiu.ocr.imageCategories.general.id;
    },
    images() {
      const images = this.examples[this.category];

      if (!images) return [];

      if (!this.isGeneral) return this.updatePaths(images);

      const samples = images[this.lang];

      if (!samples) return [];

      return this.updatePaths(samples);
    },
    exampleFile() {
      if (this.index === -1) return {};

      return this.images[this.index];
    },
    additionalStep() {
      return this.isGeneral ? 1 : 0;
    },
  },
  watch: {
    lang() {
      this.handleUploadRemove();
    },
    index() {
      this.uploadedImage = null;
    },
    category() {
      this.handleUploadRemove();
    },
  },
  async mounted() {
    this.examples = await this.$api.aiu.ocr.getSamples();
  },
  methods: {
    async getFile() {
      if (this.uploadedImage) return Promise.resolve(this.uploadedImage);

      if (this.index === -1) return Promise.reject(new Error('No file is selected!'));

      const { data: blob } = await this.$api.util.getFileAsBlob(this.exampleFile.path);

      return Promise.resolve(blob);
    },
    handleExampleClick(index) {
      if (this.isLoading) return;

      this.index = index;
    },
    updatePaths(images) {
      return images.map((image) => ({ ...image, path: `${this.$consts.config.CDN.SERVER}${image.path}` }));
    },
    async handleRun() {
      this.isLoading = true;
      const formData = new FormData();
      const blob = await this.getFile();
      formData.append('file', blob);

      try {
        const { data } = await this.$api.aiu.ocr.postImageCategory(this.category, formData, { lang: this.lang });
        this.content = this.isGeneral ? data : { result: Object.entries(data.result), confidence: data.result.confidence };
      } catch (ex) {
        ErrorService.displayErrorAlert(ex);
      } finally {
        this.isLoading = false;
      }
    },
    handleUploadRemove() {
      this.index = -1;
      this.content = {};
      this.uploadedImage = null;
      this.currentExample = null;
    },
    handleUploadSuccess(file) {
      this.uploadedImage = file;
    },
  },
};
</script>

<style lang="scss">
.example-images-carousel {
  margin-top: 0.16rem;
}

.download-image {
  text-align: right;
}

.input-language {
  margin-top: 0.16rem;
}

.result-container {
  &__image {
    width: 100%;
    margin-bottom: 0.16rem;
  }

  &__text {
    margin-top: 0.08rem;
    font-size: 0.16rem;
    line-height: 1.5;

    > span {
      font-weight: 600;
      color: #3A71FF;
    }
  }

  > table {
    border-spacing: 0;
    > thead {
      border-radius: 4px;
      background: #FFFFFF;

      > th {
        padding: 0.12rem 0.2rem;
        margin: 0;
        text-align: left;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.16rem;
        line-height: 0.24rem;
        color: $text-primary;
      }
    }

    > tbody {
      :last-child {
        > * {
          border: none;
        }

        :first-child {
          border-bottom-left-radius: 4px;
        }

        :last-child {
          border-bottom-right-radius: 4px;
        }
      }

      > tr {
        background: rgba(255, 255, 255, 0.4);

        > td {
          padding: 0.12rem 0.2rem;
          border-bottom: 1px solid #CBD3EC;
          text-align: left;
          white-space: nowrap;
          font-weight: 500;
          font-size: 0.14rem;
          line-height: 0.21rem;
          color: $text-primary;
        }
      }
    }
  }
}

.active {
  fill: #fff;
}
</style>
